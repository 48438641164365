.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  width: 100%;
  fill: inherit;

  transform-origin: center;
  animation: rotate 1s linear infinite;
}

.circle {
  fill: none;
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  fill: var(--yellow);

  height: 100%;
}

.content {
  fill: var(--yellow);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
}
